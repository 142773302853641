// Generated by Framer (0b8b5e5)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./DottY4Mq0-0.js";

const serializationHash = "framer-2ADoE"

const variantClassNames = {mOJfI6zUp: "framer-v-vxste"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {XCjGPTapi: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "mOJfI6zUp", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-vxste", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"mOJfI6zUp"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-ieisye"} data-framer-name={"Graphic"} fill={"black"} intrinsicHeight={800} intrinsicWidth={800} layoutDependency={layoutDependency} layoutId={"y80RRYxZn"} svg={"<svg width=\"800\" height=\"800\" viewBox=\"0 0 25 25\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><circle cx=\"12.5\" cy=\"12.5\" r=\"1.5\" fill=\"#142251\" stroke=\"#142251\" stroke-width=\"1.2\"/></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2ADoE.framer-10yxvs4, .framer-2ADoE .framer-10yxvs4 { display: block; }", ".framer-2ADoE.framer-vxste { height: 16px; overflow: hidden; position: relative; width: 16px; }", ".framer-2ADoE .framer-ieisye { flex: none; height: 16px; left: 0px; position: absolute; top: 0px; width: 16px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDottY4Mq0: React.ComponentType<Props> = withCSS(Component, css, "framer-2ADoE") as typeof Component;
export default FramerDottY4Mq0;

FramerDottY4Mq0.displayName = "Dot";

FramerDottY4Mq0.defaultProps = {height: 16, width: 16};

addFonts(FramerDottY4Mq0, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})